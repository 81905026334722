html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; /* 엘리먼트의 box-sizing 값을 border-box로 설정 */
}
#root {
  height: 100%;
}
ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
/* 링크에 색상 및 밑줄 없애기 */
a {
  color: inherit;
  text-decoration: none;
}
/* input number 화살표 없애기 */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
* {
  box-sizing: inherit; /* 모든 엘리먼트의 box-sizing 값을 border-box로 설정 */
}
